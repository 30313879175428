<template>
  <div class="table-responsive" style="max-height: 75vh">
    <SpinnerLoader :loading="mediaPlanTableStatus" />
    <template v-if="mediaPlanTable && mediaPlanTable.data && mediaPlan && mediaPlanTableStatus === 'success'">
      <!-- List view -->
      <table v-if="isListView" id="mp-grid-table-list" class="table small table-sm ws-nowrap table-hover sticky-footer sticky-headers">
        <thead>
          <tr>
            <th>{{ $t('table.date') }}</th>
            <th>{{ $t('table.time') }}</th>
            <th>
              <div class="resizable">{{ $t('broadcastSideBar.program') }} <br />({{ $t('broadcastSideBar.block') }})</div>
            </th>
            <th>{{ $t('table.time') }} <br />({{ $t('broadcastSideBar.block') }})</th>
            <th>{{ $t('table.commercial') }}</th>
            <th class="text-right">{{ $t('table.durationShort') }}</th>
            <th class="text-right">{{ $t('table.position') }}</th>
            <th class="text-center">{{ $t('table.released') }}</th>

            <th class="text-right">{{ $t('table.time') }} <br />({{ $t('table.fact') }})</th>
            <th class="text-right">CPP <br />({{ $t('table.plan') }})</th>
            <th class="text-right">CPP <br />({{ $t('table.fact') }})</th>
            <th class="text-right">{{ $t('table.discount') }}(-)/<br />{{ $t('table.surcharge') }}(+) <br />({{ $t('table.plan') }})</th>
            <th class="text-right">{{ $t('table.discount') }}(-)/<br />{{ $t('table.surcharge') }}(+) <br />({{ $t('table.fact') }})</th>
            <th v-if="auctionEnabled === 'TRUE'" class="text-right" style="white-space: break-spaces">{{ $t('auction.auctionBid') }}</th>

            <th class="text-right" style="white-space: break-spaces">{{ $t('table.surchargeForCoBrand') }}</th>

            <th class="text-right">
              {{ $t('table.plan') }} <br />
              {{ show_equals ? 'Equals' : 'wGRP' }}
            </th>
            <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }} <br />{{ show_equals ? 'Equals' : 'wGRP' }}</th>
            <th class="text-right">{{ $t('table.fact') }} <br />{{ show_equals ? 'Equals' : 'wGRP' }}</th>
            <th class="text-right">{{ $t('table.price') }} <br />({{ $t('table.plan') }})</th>
            <th class="text-right">{{ $t('table.price') }} <br />({{ $t('table.plan') }}+{{ $t('table.fact') }})</th>
            <th class="text-right">{{ $t('table.price') }} <br />({{ $t('table.fact') }})</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="mediaPlanTableFiltered.spots && Object.keys(mediaPlanTableFiltered.spots).length === 0">
            <td colspan="19" class="text-center">{{ $t('table.noCommercialPlacements') }}</td>
          </tr>
          <tr v-for="(program, index) in mediaPlanTableFiltered.spots" :key="index">
            <td>
              <template v-if="program.program_release_broadcast_day">
                {{ program.program_release_broadcast_day | convertDate }}
              </template>
              <template v-else>
                {{ program.block_broadcast_day | convertDate }}
              </template>
            </td>
            <td>
              <template v-if="program.program_release_start_interval != null">
                {{ sec2time(program.program_release_start_interval) }}
              </template>
              <i v-else>
                {{ sec2time(program.block_start_interval) }}
              </i>
            </td>
            <td>
              <template v-if="program.program_release_start_interval != null">
                {{ program.program_name }}{{ program.program_release_name ? ' - ' + program.program_release_name : '' }}
                {{ program.block_number ? '(' + program.block_number + ')' : '' }}
              </template>
              <i v-else>
                {{ $t('table.crossProgramBlock') }}
              </i>
            </td>
            <td>{{ sec2time(program.block_start_interval) }}</td>
            <td>
              <p class="mb-0">{{ program.commercial_id_name_mark }}</p>
            </td>
            <td class="text-right">{{ program.commercial_duration }}</td>
            <td class="text-right" :class="{ 'bg-position-warning': program.spot_position && program.spot_equal_fact_position === 0 }">
              {{ program.spot_position }}
            </td>
            <td class="text-center">
              {{ program.is_released ? '+' : '-' }}
            </td>
            <td class="text-right">{{ program.spot_fact_start_interval ? sec2time(program.spot_fact_start_interval) : '' }}</td>
            <td class="text-right">{{ program.cpp_plan | toFixedAndSpace }}</td>
            <td class="text-right">{{ program.cpp_fact | toFixedAndSpace }}</td>
            <td class="text-right">{{ program.discount | toFixedAndSpace }}%</td>
            <td class="text-right">{{ program.fact_discount | toFixedAndSpace }}%</td>
            <td v-if="auctionEnabled === 'TRUE'" class="text-right">
              <div v-if="program.spot_auction_coeff">{{ program.spot_auction_coeff * 100 }}%</div>
            </td>
            <td class="text-right">
              <template v-if="program.co_branding_discount !== null">{{ program.co_branding_discount | toFixedAndSpace }}%</template>
            </td>
            <td class="text-right">
              {{ program.spot_plan_grp | toFixedAndSpace }}
            </td>
            <td class="text-right">
              {{ program.spot_plan_fact_wgrp | toFixedAndSpace }}
            </td>
            <td class="text-right">
              {{ program.spot_fact_grp | toFixedAndSpace }}
            </td>
            <td class="text-right">
              {{ program.spot_plan_price | toFixedAndSpace }}
            </td>
            <td class="text-right">
              {{ program.spot_plan_fact_price | toFixedAndSpace }}
            </td>
            <td class="text-right pr-1">
              {{ program.spot_fact_price | toFixedAndSpace }}
            </td>
          </tr>
        </tbody>
        <tfoot v-if="mediaPlanTableFiltered.totals">
          <tr class="font-weight-bold">
            <td colspan="4">{{ $t('booking.total') }}</td>
            <td>{{ mediaPlanTableFiltered && mediaPlanTableFiltered.spots ? Object.keys(mediaPlanTableFiltered.spots).length : '' }}</td>
            <td class="text-right">{{ (+mediaPlanTableFiltered.totals.duration).toFixed(0) }}</td>
            <td :colspan="auctionEnabled === 'TRUE' ? 9 : 8"></td>
            <td class="text-right">{{ mediaPlanTableFiltered.totals.plan_grp | toFixedAndSpace }}</td>
            <td class="text-right">{{ mediaPlanTableFiltered.totals.plan_fact_wgrp | toFixedAndSpace }}</td>
            <td class="text-right">{{ mediaPlanTableFiltered.totals.fact_grp | toFixedAndSpace }}</td>
            <td class="text-right">{{ mediaPlanTableFiltered.totals.plan_price | toFixedAndSpace }}</td>
            <td class="text-right">{{ mediaPlanTableFiltered.totals.plan_fact_price | toFixedAndSpace }}</td>
            <td class="text-right pr-1">{{ mediaPlanTableFiltered.totals.fact_price | toFixedAndSpace }}</td>
          </tr>
        </tfoot>
      </table>

      <!-- Table View -->
      <table
        v-else
        id="mp-grid-table"
        class="table small table-sm ws-nowrap table-hover sticky-footer sticky-headers table-bordered"
        :set="(daysInMonth = new Date(mediaPlan.date_from.slice(0, 4), mediaPlan.date_from.slice(5, 7), 0).getDate())"
      >
        <thead>
          <tr>
            <th>
              <div class="resizable">{{ $t('broadcastSideBar.program') }} ({{ $t('broadcastSideBar.block') }})</div>
            </th>
            <th>{{ $t('table.time') }}</th>
            <th v-for="i in daysInMonth" :key="i">{{ i }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="mediaPlanTable && mediaPlanTable.data && Object.keys(mediaPlanTable.data).length === 0">
            <td :colspan="daysInMonth + 2" class="text-center">{{ $t('table.noCommercialPlacements') }}</td>
          </tr>
          <tr v-for="(time, index) in mediaPlanTable.data" :key="index">
            <td>
              <template v-if="time.program_name != null">
                {{ time.program_name }}{{ time.program_release_name ? ' - ' + time.program_release_name : '' }}
                {{ time.block_number ? '(' + time.block_number + ')' : '' }}
              </template>
              <i v-else>
                {{ $t('table.crossProgramBlock') }}
              </i>
            </td>
            <td>{{ index }}</td>
            <td v-for="ind in daysInMonth" :key="ind" class="text-center">
              <template v-for="(days, daysIndex) in time.days">
                <template v-if="parseInt(daysIndex) === ind">
                  <abbr
                    v-for="(object, objectIndex) in days"
                    :key="daysIndex + '_' + objectIndex"
                    :set="(currCommercial = mediaPlan.commercials.find((el) => el.mark === object.mark))"
                    :title="currCommercial ? `[${currCommercial.id}] ${currCommercial.name} (${currCommercial.mark})` : 'test'"
                  >
                    {{ object.mark }}
                  </abbr>
                </template>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import convertDate from '@/filters/convertDate';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import timeConvertHelpers from '@/mixins/timeConvertHelpers';
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';

export default {
  filters: {
    convertDate,
    toFixedAndSpace,
  },
  components: { SpinnerLoader },
  mixins: [timeConvertHelpers],
  props: {
    mediaPlan: {
      required: true,
      type: Object,
    },
    isListView: {
      type: Boolean,
    },
    selectedDuration: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      auctionEnabled: process.env.VUE_APP_ENABLE_AUCTIONS,
    };
  },

  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      mediaPlanTable: 'getMediaPlanTable',
      mediaPlanTableStatus: 'getMediaPlanTableStatus',
      sh_settings: 'getSalehousesSettings',
    }),
    show_equals() {
      if (this.FEATURES.EQUALS) {
        return this.sh_settings[0]?.is_equals_enabled ?? false;
      } else {
        return false;
      }
    },
    mediaPlanTableFiltered() {
      if (this.isListView && this.selectedDuration && this.mediaPlanTable?.data?.spots) {
        // 'selectedDuration = 0' => show all
        const filteredTable = { spots: [], totals: {} }; // to unlink variables from each other
        filteredTable.spots = this.mediaPlanTable.data.spots.filter((e) => e.commercial_duration === +this.selectedDuration);
        filteredTable.totals.duration = filteredTable.spots.reduce((acc, current) => acc + +current.commercial_duration, 0);
        filteredTable.totals.plan_grp = filteredTable.spots.reduce((acc, current) => acc + +current.spot_plan_grp, 0);
        filteredTable.totals.plan_fact_wgrp = filteredTable.spots.reduce((acc, current) => acc + +current.spot_plan_fact_wgrp, 0);
        filteredTable.totals.fact_grp = filteredTable.spots.reduce((acc, current) => acc + +current.spot_fact_grp, 0);
        filteredTable.totals.plan_price = filteredTable.spots.reduce((acc, current) => acc + +current.spot_plan_price, 0);
        filteredTable.totals.plan_fact_price = filteredTable.spots.reduce((acc, current) => acc + +current.spot_plan_fact_price, 0);
        filteredTable.totals.fact_price = filteredTable.spots.reduce((acc, current) => acc + +current.spot_fact_price, 0);
        return filteredTable;
      } else {
        return this.mediaPlanTable.data;
      }
    },
  },
  async mounted() {
    let channel = this.$store.getters.getModalEditChannels;

    if (channel == '' || this.mediaPlan.channel.id != channel.id) {
      await this.$store.dispatch('GET_CHANNELS_ID', this.mediaPlan.channel.id);
    }
  },
};
</script>

<style lang="sass">
table#mp-grid-table
  thead
    th
      div.resizable
        overflow: auto
        min-width: 110px

      &:hover .resizable
        resize: horizontal

  tr > td:first-child
    max-width: 110px
    width: 110px
    overflow: hidden
    text-overflow: ellipsis

table#mp-grid-table-list
  thead
    th
      div.resizable
        overflow: auto
        min-width: 110px

      &:hover .resizable
        resize: horizontal

  tr > td:nth-child(3)
    max-width: 110px
    width: 110px
    overflow: hidden
    text-overflow: ellipsis
</style>
